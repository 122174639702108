<section class="relative text-gray-600 bg-gray-100 md:bg-internet-white md:bg-top body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-32 mx-auto">
        <div class="lg:w-2/3 flex flex-col sm:flex-row sm:items-start items-center text-center sm:text-left mx-auto">
            <h1 class="flex-grow sm:pr-16 text-2xl max-w-2xl font-medium title-font text-gray-900">
                We are hiring experts worldwide! Write a coding challenge and skip long application processes :)
            </h1>
            <button
                (click)="goToLink('https://app.tekr.io')"
                id="careers-section-cta"
                    class="click-tag inline-flex mt-12 mb-8 sm:mt-0 text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
                Apply
            </button>
        </div>
    </div>
</section>
