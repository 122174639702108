import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
// import {OdooConnector} from '../../../ng-odoo-connect/odoo-connector.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'tekr-lp',
    templateUrl: './lp.component.html',
    styleUrls: ['./lp.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LpComponent implements OnInit {

    /*odoo = new OdooConnector(
        'https://backoffice.tekr.io',
        // 'https://stage.backoffice.tekr.io',
        // 'http://backoffice.local',
        'tekr-erp',
        'admin@tekr.io',
        'e2762fa8e08d3b58ee9622d93cef8f958a295420',
        // '95b9e3f1c197915e7763ab8e448d49ffcdc714c8',
        // '2897720084af565e7f765b81f89fede9d4dad20d'
    );*/

    lpForm: FormGroup;
    fromGroupHidden = false;

    constructor(private viewportScroller: ViewportScroller, private ref: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.lpForm = new FormGroup({
            consulting: new FormControl(),
            srs: new FormControl(),
            funding: new FormControl(),
            erp: new FormControl(),
            saas: new FormControl(),
            ai: new FormControl(),
            blockchain: new FormControl(),
            allocation: new FormControl(),
            others: new FormControl(),
            name: new FormControl(),
            company: new FormControl(),
            email: new FormControl(),
            phone: new FormControl(),
            message: new FormControl(),
            budget: new FormControl(),
            agreement: new FormControl(),
        });
        localStorage.setItem('refreshed', 'false')
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }

    sendLpForm(): void {
        const formData = this.lpForm.getRawValue();

        if (!formData.email) { return alert('Please enter your email address.'); }
        if (!formData.agreement) { return alert('Please agree to our Privacy Policy.'); }

        this.lpForm.disable();
        const tags = [];
        tags.push(22);
        tags.push(25);

        const contactData = {
            email: formData.email,
            name: formData.email,
            category_id: tags,
            // team_id: 1
        };
        this.createLead(contactData);
    }

    updateLpForm(): void {
        const id = localStorage.getItem('customerId');
        const formData = this.lpForm.getRawValue();

        this.lpForm.disable();
        const tags = [];
        tags.push(22);
        tags.push(25);

        if (formData.consulting) {
            tags.push(23);
        }
        if (formData.srs) {
            tags.push(24);
        }
        if (formData.funding) {
            tags.push(1);
        }
        if (formData.erp) {
            tags.push(2);
        }
        if (formData.saas) {
            tags.push(3);
        }
        if (formData.ai) {
            tags.push(4);
        }
        if (formData.blockchain) {
            tags.push(5);
        }
        if (formData.others) {
            tags.push(6);
        }
        if (formData.allocation) {
            tags.push(10);
        }
        if (formData.budget) {
            tags.push(parseInt(formData.budget));
        }

        const contactData = {
            email: localStorage.getItem('customerMail'),
            name: formData.name || localStorage.getItem('customerMail'),
            phone: formData.phone,
            comment: formData.message,
            website: formData.company,
            category_id: tags,
            // team_id: 1
        };

        this.updateLead(id, contactData);
    }

    // tslint:disable-next-line:typedef
    updateLead(id, values) {
        document.getElementById('lpForm').innerHTML = '' +
            '<div class="flex flex-col text-center mx-auto mb-10">' +
            '<p class="font-bold">' +
            'Thank you for this information!' +
            '</p>' +
            '</div>';
        this.onClick('contact-lp');
        /*this.odoo.login().subscribe((res: any) => {
            this.odoo.create(
                'res.partner',
                values
                // [['customer', '=', true]],
                // {fields: ['name'], limit: 5}
            ).subscribe((obj: any) => {
                // console.log(obj);
                document.getElementById('lpForm').innerHTML = '' +
                    '<div class="flex flex-col text-center mx-auto mb-10">' +
                    '<p class="font-bold">' +
                    'Thank you for this information!' +
                    '</p>' +
                    '</div>';
                this.onClick('contact-lp');
                this.odoo.delete(
                    'res.partner',
                    parseInt(id)
                    // [['customer', '=', true]],
                    // {fields: ['name'], limit: 5}
                ).subscribe((obj: any) => {
                    // console.log(obj);
                })
            });
        });*/
    }

    // tslint:disable-next-line:typedef
    createLead(values) {
        // [Demo] Get Odoo server Data
        // this.odoo.data().subscribe((res: any) => res);
        localStorage.setItem('customerMail', values.email);
        this.fromGroupHidden = true;
        this.lpForm.enable();
        this.ref.detectChanges();
        this.onClick('contact-lp');
// [Demo] LogIn & SearchRead res.partner
        /*this.odoo.login().subscribe((res: any) => {
            this.odoo.create(
                'res.partner',
                values
                // [['customer', '=', true]],
                // {fields: ['name'], limit: 5}
            ).subscribe((id: any) => {
                localStorage.setItem('customerId', id);
                localStorage.setItem('customerMail', values.email);
                this.fromGroupHidden = true;
                this.lpForm.enable();
                this.ref.detectChanges();
                this.onClick('contact-lp');
            });
        });*/
    }
}
