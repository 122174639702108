import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErpComponent} from './erp/erp.component';
import {HomeComponent} from './home/home.component';
import {SaasComponent} from './saas/saas.component';
import {AiComponent} from './ai/ai.component';
import {ContactComponent} from './contact/contact.component';
import {ImprintComponent} from './imprint/imprint.component';
import {AboutComponent} from './about/about.component';
import {CareersComponent} from './careers/careers.component';
import {BlockchainComponent} from './blockchain/blockchain.component';
import {CareersBrComponent} from './careers-br/careers-br.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {OrganizationsComponent} from './organizations/organizations.component';
import {DevelopersComponent} from './developers/developers.component';
import {DevelopersBrComponent} from './developers-br/developers-br.component';
import {CookiePolicyComponent} from './cookie-policy/cookie-policy.component';
import {TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import {ServicesComponent} from './services/services.component';
import {LpComponent} from './lp/lp.component';
import {LpAiComponent} from './lp-ai/lp-ai.component';
import {LpErpComponent} from './lp-erp/lp-erp.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            title: 'tekr - Making AI usable',
            description: 'Making AI usable. Our AI-driven platform allows organizations to plan, state-fund and develop software projects in a sustainable and cost-efficient way.',
            ogTitle: 'tekr - Making AI usable',
        }
    },
    {
        path: 'services',
        component: ServicesComponent,
        data: {
            title: 'tekr - Services',
            description: 'State-funded web, mobile and AI development. IT services that increase software productivity. From web to ML applications, we help fund and develop software under highest quality standards.',
            ogTitle: 'tekr - Services',
        }
    },
    /*
    {
        path: 'organizations',
        component: OrganizationsComponent,
        data: {
            title: 'tekr - Organizations',
            description: 'Outsource IT projects. Get state-funded.',
            ogTitle: 'tekr - Organizations',
        }
    },*/
    {
        path: 'developers',
        component: DevelopersComponent,
        data: {
            title: 'tekr - Developers',
            description: 'Build great stuff while working from anywhere. Be part of an international team of high performance remote developers and work with leading experts in the European market.',
            ogTitle: 'tekr - Developers',
        }
    },
    {
        path: 'devs',
        component: DevelopersComponent,
        data: {
            title: 'tekr - Developers',
            description: 'Build great stuff while working from anywhere. Be part of an international team of high performance remote developers and work with leading experts in the European market.',
            ogTitle: 'tekr - Developers',
        }
    },
    {
        path: 'developers/br',
        component: DevelopersBrComponent,
        data: {
            title: 'Ganhe até 30K/mês trabalhando de casa! Data Science, Blockchain, Frontend, Backend, Fullstack - Developers - tekr',
            description: 'Trabalhe de casa ganhando em Euro. Faça parte de uma equipe internacional de devs de alta performance e trabalhe com especialistas de ponta do mercado Europeu.',
            ogTitle: 'Ganhe até 30K/mês trabalhando de casa! Data Science, Blockchain, Frontend, Backend, Fullstack - Developers - tekr',
        }
    },
    {
        path: 'devs/br',
        component: DevelopersBrComponent,
        data: {
            title: 'Ganhe até 30K/mês trabalhando de casa! Data Science, Blockchain, Frontend, Backend, Fullstack - Developers - tekr',
            description: 'Trabalhe de casa ganhando em Euro. Faça parte de uma equipe internacional de devs de alta performance e trabalhe com especialistas de ponta do mercado Europeu.',
            ogTitle: 'Ganhe até 30K/mês trabalhando de casa! Data Science, Blockchain, Frontend, Backend, Fullstack - Developers - tekr',
        }
    },
    {
        path: 'erp',
        component: ErpComponent,
        data: {
            title: 'ERP, CRM, MRP, HR and more - Everything together - tekr',
            description: 'Forget those chaotic sheets and manage everything on a single integrated platform. Install the app you need as you grow, with 50% EU grant.',
            ogTitle: 'ERP, CRM, MRP, HR - Everything together - tekr',
        }
    },
    {
        path: 'saas',
        component: SaasComponent,
        data: {
            title: 'Web and mobile apps, APIs, Bots, Automation, Payments - SaaS Development - tekr',
            description: 'AI-oriented web and mobile development. We help SaaS products grow and build amazing services. Automate your business processes and improve customer relations.',
            ogTitle: 'Web and mobile apps, APIs, Bots, Automation, Payments - SaaS Development - tekr',
        }
    },
    {
        path: 'ai',
        component: AiComponent,
        data: {
            title: 'NLP, Question Answering, Text Processing - AI Development - tekr',
            description: 'Be light-years ahead your competitors. We create and enhance innovative tools with the craft of artificial intelligence. From ML to NLP, we develop custom solutions using state-of-the-art AI.',
            ogTitle: 'NLP, Question Answering, Text Processing - AI Development - tekr',
        }
    },
    {
        path: 'blockchain',
        component: BlockchainComponent,
        data: {
            title: 'DAOs, DeFi, Smart Contracts, NFTs - Blockchain Development - tekr',
            description: 'Robust decentralized system for processing sensitive data. We have built a trusting machine for public administration, finance, insurance, supply chain and many other industries.',
            ogTitle: 'DAOs, DeFi, Smart Contracts, NFTs - Blockchain Development - tekr',
        }
    },
    {
        path: 'contact',
        component: ContactComponent,
        data: {
            title: 'Contact - tekr',
            description: 'Ready to discuss about your project? You may contact us via chat, Whatsapp, email or just send us a message.',
            ogTitle: 'Contact - tekr',
        }
    },
    /*    {
            path: 'about',
            component: AboutComponent
        },*/
    /*{
        path: 'careers',
        component: CareersComponent,
        data: {
            title: 'Earn up to 6K/month working from home! Data Science, Blockchain, Frontend, Backend, Fullstack - Careers - tekr',
            description: 'Work from anywhere. Earn in Euros. Be part of an international team of high performance devs and work with leading experts in the European market.',
            ogTitle: 'Data Science, Blockchain, Frontend, Backend, Fullstack - Careers - tekr',
        }
    },,*/
    {
        path: 'careers',
        redirectTo: 'developers'
    },
    {
        path: 'careers/br',
        redirectTo: 'developers/br'
    },
    {
        path: 'imprint',
        component: ImprintComponent,
        data: {
            title: 'Imprint - tekr',
            description: 'Imprint tekr.',
            ogTitle: 'Imprint - tekr',
        }
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
            title: 'Privacy Policy - tekr',
            description: 'Privacy Policy tekr.',
            ogTitle: 'Privacy Policy - tekr',
        }
    },
    {
        path: 'cookie-policy',
        component: CookiePolicyComponent,
        data: {
            title: 'Cookie Policy - tekr',
            description: 'Cookie Policy tekr.',
            ogTitle: 'Cookie Policy - tekr',
        }
    },
    {
        path: 'terms-of-use',
        component: TermsOfUseComponent,
        data: {
            title: 'Terms of Use - tekr',
            description: 'Terms of Use - tekr.',
            ogTitle: 'Terms of Use - tekr',
        }
    },
    /*{
        path: 'lp',
        component: LpComponent,
        data: {
            title: 'tekr - Making AI usable',
            description: 'State-funded web, mobile and AI development. IT services that increase software productivity. From web to ML applications, we help fund and develop software under highest quality standards.',
            ogTitle: 'tekr - Making AI usable',
        }
    },*/
    {
        path: 'state-funded-software',
        component: LpAiComponent,
        data: {
            title: 'tekr - Making AI usable',
            description: 'State-funded web, mobile and AI development. IT services that increase software productivity. From web to ML applications, we help fund and develop software under highest quality standards.',
            ogTitle: 'tekr - Making AI usable',
        }
    },
    {
        path: 'state-funded-erp',
        component: LpErpComponent,
        data: {
            title: 'tekr - ERP, CRM, MRP, HR and more - Everything together',
            description: 'Forget those chaotic sheets and manage everything on a single integrated platform. Install the app you need as you grow, with 50% EU grant.',
            ogTitle: 'ERP, CRM, MRP, HR - Everything together - tekr',
        }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
