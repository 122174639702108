import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tekr-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      localStorage.setItem('refreshed', 'false')
  }

}
