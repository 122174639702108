<section class="relative text-gray-600 bg-white dark:bg-gray-900 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-white dark:text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
            <!--            <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">FOR ANY KIND OF SOFTWARE</h2>-->
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">Software development of
                success</h1>
            <p class="mx-auto max-w-prose">
                Europe wants to digitalize. We support you from developing the software you need
                to actually getting it funded by the state.
                That way, you can profit from the subsidies without loosing focus on your business.
            </p>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div
                class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                </svg>
            </div>
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Consulting</h2>
                <p class="leading-relaxed text-base">
                    You can choose for a smooth cold start and get the best insights from our top IT experts.
                    Allow us to review your project's requirements and lead you towards the best solutions.
                </p>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Planning</h2>
                <p class="leading-relaxed text-base">
                    Our AI accelerates software engineering while helping us to write ISO technical documents incredibly
                    fast. Those documents alone can reduce your software development costs in half.
                </p>
            </div>
            <div
                class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                </svg>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div
                class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
            </div>
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Funding</h2>
                <p class="leading-relaxed text-base">
                    After planning, you can apply for state subsidies in just a few clicks
                    directly from our platform. Within days, 50% of your company's digitalization budget gets reimbursed
                    by the EU.
                </p>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Development</h2>
                <p class="leading-relaxed text-base">
                    In order to build the best software even faster, our AI recommends your project to developers with
                    the exact right skills. Your high quality MVP is then ready to go live within 3 months.
                </p>
            </div>
            <div
                class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                </svg>
            </div>
        </div>
        <button
            (click)="goToLink('https://tekr.io/contact')"
            id="pipeline-section-cta"
            class="click-tag flex flex-auto mx-auto text-center mt-15 mb-10 text-gray-100 dark:text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
            Start now
        </button>
    </div>
</section>
