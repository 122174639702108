<section class="relative bg-gray-900 md:bg-tech-purple md:bg-top body-font">
<!--         *ngIf="content$ | async as content"-->
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-32 mx-auto">
        <div class="lg:w-2/3 flex flex-col sm:flex-row sm:items-start items-center text-center sm:text-left mx-auto">
            <!--            <h1 class="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-100 whitespace-pre-wrap">{{content.data.attributes.title}}</h1>-->
            <h1 class="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-100 whitespace-pre-wrap">
                Ready to innovate with us?<br/>
                Unleash your growth potential.
            </h1>
            <a routerLink="/contact"
               id="contact-section-cta"
               class="click-tag mt-12 sm:mt-0 inline-flex text-indigo-500 bg-gray-100 dark:text-gray-100 dark:bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 dark:hover:bg-indigo-300 rounded text-lg">
                Let's talk
            </a>
        </div>
    </div>
</section>
