<section class="text-gray-100 bg-indigo-500 md:bg-tech-white md:bg-center body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font text-gray-100 dark:text-gray-100 sm:text-4xl text-3xl mb-4 font-medium max-w-lg">
                Robust decentralized system for processing sensitive data
            </h1>
            <p class="mb-8 text-gray-100 dark:text-gray-100 leading-relaxed max-w-prose">
                We have built a trusting machine for public administration, finance, insurance, supply chain and many
                other industries.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <button (click)="onClick('blockchain')"
                        id="blockchain-lm"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                    Learn more
                </button>
                <a routerLink="/contact"
                   id="blockchain-cta"
                   class="click-tag ml-2 md:ml-4 inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                    Contact us
                </a>
            </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        </div>
    </div>
</section>

<section class="relative text-gray-600 bg-gray-100 md:bg-connections-black-inv md:bg-top body-font" id="blockchain">
    <div class="relative" id="services">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-gray-900 text-center text-2xl font-medium title-font mb-2 text-gray-100">
                        Europe's key technology<br/>
                        for digital transformation</h1>
                    <!--                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>-->
                    <!--<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>-->
                </div>
            </div>
        </div>
    </div>
    <div class="container px-5 py-5 pb-36 mx-auto flex flex-wrap">
        <div class="flex flex-wrap -m-4">
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">DAOs and DeFi</h2>
                        <p class="leading-relaxed text-base">Financial instruments
                            without relying on intermediaries such as brokerages, exchanges or banks. Decentralized
                            autonomous organizations.
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Smart contracts</h2>
                        <p class="leading-relaxed text-base">Automatically execute, control or document legally relevant
                            events and actions according to the terms of a contract or an agreement.</p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">NFTs - Non-fungible tokens</h2>
                        <p class="leading-relaxed text-base">Solutions from simple token development to NFT
                            marketplaces.
                            Token management for digital and physical assets (e.g. real estate).</p>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:w-1/2 md:w-full">
                <div
                    class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <div
                        class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"/>
                        </svg>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Supply chain traceability</h2>
                        <p class="leading-relaxed text-base">
                            It goes beyond sustainability. Traceability can help companies improve efficiency,
                            resilience and competitiveness.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<tekr-contact-section></tekr-contact-section>
<tekr-pipeline-section></tekr-pipeline-section>
<tekr-careers-section></tekr-careers-section>
