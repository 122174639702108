import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {
    trustedUrl: any;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        if (localStorage.getItem('refreshed') !== 'true') {
            localStorage.setItem('refreshed', 'true')
            location.reload()
        }
    }

}
