<section class="text-gray-600 bg-white dark:bg-gray-900 md:bg-people-purple md:bg-top body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font max-w-prose sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                State-funded digital transformation for your company
            </h1>
            <p class="mb-8 leading-relaxed max-w-prose">
                Forget those chaotic sheets and manage everything on a single integrated platform.
                Install the app you need as you grow, with 50% EU grant.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <button (click)="onClick('odoo')"
                        id="lp-erp-lm"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                    Learn more
                </button>
                <button (click)="onClick('contact-lp')"
                        id="lp-erp-cta"
                        class="click-tag ml-2 md:ml-4 inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 rounded text-lg">
                    Contact us
                </button>
            </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        </div>
    </div>
</section>


<section class="relative text-gray-100 dark:text-gray-100 bg-indigo-500 body-font" id="odoo">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-indigo-500 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">

        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="sm:text-3xl text-2xl max-w-2xl font-medium title-font mb-4">
                Odoo, the open-source ERP software
                validated by more than 7 million users</h1>
            <p class="text-base leading-relaxed max-w-prose mb-12">What makes Odoo different
                from other ERPs
                is the fluidity and total integration that cover the needs of even the most complex companies. The
                flexibility that Odoo offers is such that applications can be added as the company’s needs evolve
                and
                its customer base grows.</p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <button (click)="onClick('apps')"
                        id="lp-erp-apps-lm"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                    Discover apps
                </button>
                <button (click)="onClick('contact-lp')"
                        id="lp-erp-apps-cta"
                        class="click-tag ml-2 md:ml-4 inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                    Let's talk
                </button>
            </div>
        </div>
        <div class="lg:max-w-lg md:w-1/2 w-full">
            <iframe width="100%" height="380px" src="https://www.youtube.com/embed/y7TlnAv6cto?controls=1&modestbranding"
                    title="Odoo ERP" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
        </div>
    </div>
</section>

<section class="text-gray-600 bg-white dark:bg-gray-900 body-font" id="apps">
    <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-1/2 w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 lg:pr-6">Sales apps</h1>
            <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 space-y-0">
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('KxZAdEGpYAw')">
                        <img src="assets/img/apps/crm.png" alt="CRM"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">CRM</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('VMuCr5_arsY')">
                        <img src="assets/img/apps/sales.png" alt="Sales"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Sales</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('oxOcmcmGkXw')">
                        <img src="assets/img/apps/pos.png" alt="POS"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Point of Sale</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/subscriptions.png" alt="Subscriptions"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Subscriptions</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('0RPeGbyp3vM')">
                        <img src="assets/img/apps/rental.png" alt="Rental"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Rental</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="text-gray-600 bg-gray-100 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-1/2 w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 lg:pr-6">Finance apps</h1>
            <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 space-y-0">
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('-FgcAUUsI7k')">
                        <img src="assets/img/apps/accounting.png" alt="Accounting"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Accounting</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/invoicing.png" alt="Invoicing"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Invoicing</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/expenses.png" alt="Expenses"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Expenses</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('Ayab6wZ_U1A')">
                        <img src="assets/img/apps/documents.png" alt="Documents"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Documents</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/sign.png" alt="Sign"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Sign</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="text-gray-600 bg-white dark:bg-gray-900 body-font">
    <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-1/2 w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 lg:pr-6">Inventory & Manufacturing
                apps</h1>
            <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 space-y-0">
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('ZIn36UmkEk8')">
                        <img src="assets/img/apps/inventory.png" alt="Inventory"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Inventory</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('xrf7zIACGvw')">
                        <img src="assets/img/apps/manufacturing.png" alt="Manufacturing (MRP)"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Manufacturing (MRP)</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/plm.png" alt="PLM"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">PLM</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/purchase.png" alt="Purchase"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Purchase</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/maintenance.png" alt="Maintenance"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Maintenance</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('QtIU0p8daAo')">
                        <img src="assets/img/apps/quality.png" alt="Quality"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Quality</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="text-gray-600 bg-gray-100 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-1/2 w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 lg:pr-6">Human Resources apps</h1>
            <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 space-y-0">
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/employees.png" alt="Employees"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Employees</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('wrzf-LWruvo')">
                        <img src="assets/img/apps/recruitment.png" alt="Recruitment"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Recruitment</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/time-off.png" alt="Time Off"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Time Off</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/appraisals.png" alt="Appraisals"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Appraisals</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('2gw4ffg_lh4')">
                        <img src="assets/img/apps/referrals.png" alt="Referrals"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Referrals</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/fleet.png" alt="Fleet"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Fleet</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="text-gray-600 bg-white dark:bg-gray-900 body-font">
    <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-1/2 w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 lg:pr-6">Marketing apps</h1>
            <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 space-y-0">
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('BJXmLEUoXZA')">
                        <img src="assets/img/apps/marketing-automation.png" alt="Marketing Automation"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Marketing Automation</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('pBX9t7L0PuA')">
                        <img src="assets/img/apps/email-marketing.png" alt="Email Marketing"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Email Marketing</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('Q0JKvwPcjIY')">
                        <img src="assets/img/apps/sms-marketing.png" alt="SMS Marketing"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">SMS Marketing</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('EK9FBjxjLUQ')">
                        <img src="assets/img/apps/social-marketing.png" alt="Social Marketing"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Social Marketing</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('rKkUODAuOd0')">
                        <img src="assets/img/apps/events.png" alt="Events"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Events</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/surveys.png" alt="surveys"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Surveys</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="text-gray-600 bg-gray-100 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-1/2 w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 lg:pr-6">Services apps</h1>
            <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 space-y-0">
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('ZrFMphnpYpM')">
                        <img src="assets/img/apps/project.png" alt="Project"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Project</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('ZQmLMeP2G1Q')">
                        <img src="assets/img/apps/timesheet.png" alt="Timesheet"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Timesheet</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('HbeHXw_G61M')">
                        <img src="assets/img/apps/field-service.png" alt="Field Service"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Field Service</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('KUVV789yczA')">
                        <img src="assets/img/apps/helpdesk.png" alt="Helpdesk"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Helpdesk</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('nSq_uRLCW6g')">
                        <img src="assets/img/apps/planning.png" alt="Planning"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Planning</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('nSnc5W5cuwQ')">
                        <img src="assets/img/apps/appointments.png" alt="Appointments"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Appointments</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="text-gray-600 bg-white dark:bg-gray-900 body-font">
    <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-1/2 w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 lg:pr-6">Productivity apps</h1>
            <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 space-y-0">
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/discuss.png" alt="Discuss"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Discuss</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/approvals.png" alt="Approvals"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Approvals</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <button mat-button (click)="openDialog('ivT31w3MwH8')">
                        <img src="assets/img/apps/iot.png" alt="IoT"/>
                    </button>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">IoT</h2>
                </div>
            </div>
            <div class="p-4 w-full sm:w-1/2 md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                    <span>
                        <img src="assets/img/apps/voip.png" alt="VoIP"/>
                    </span>
                </div>
                <div class="flex-grow">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">VoIP</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="relative bg-gray-900 md:bg-tech-purple md:bg-top body-font">

    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-32 mx-auto">
        <div class="lg:w-2/3 flex flex-col sm:flex-row sm:items-start items-center text-center sm:text-left mx-auto">
            <h1 class="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-100">
                No more painful integrations. <br/>
                Unleash your growth potential.
            </h1>
            <button (click)="onClick('contact-lp')"
                    id="lp-erp-contact-cta"
                    class="click-tag mt-12 sm:mt-0 inline-flex text-indigo-500 bg-gray-100 dark:text-gray-100 dark:bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 dark:hover:bg-indigo-300 rounded text-lg">
                Get Odoo
            </button>
        </div>
    </div>
</section>

<tekr-pipeline-section></tekr-pipeline-section>


<section class="relative text-gray-600 bg-gray-100 md:bg-connections-white-inv md:bg-top body-font" id="contact-lp">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Let's talk</h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base max-w-prose">
                Our team will contact you within one business day.
            </p>
        </div>

        <form [formGroup]="lpForm" id="lpForm">
            <div class="lg:w-1/2 md:w-2/3 mx-auto max-w-md">
                <div class="flex flex-wrap -m-2" id="step1" [className]="fromGroupHidden ? 'hidden' : 'block'">
                    <div class="p-2 max-w-prose mx-auto text-center">
                        <div class="relative">
                            <input [formControlName]="'email'" type="email" id="email" name="email" placeholder="Email"
                                   class="bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>

                    <div class="flex items-center align-middle justify-center p-2">
                        <input [formControlName]="'agreement'" id="agreement" aria-describedby="agreement"
                               type="checkbox"
                               class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="agreement" class="ml-3 text-gray-900 dark:text-gray-300">I agree to the <a
                            href="https://tekr.io/privacy-policy" target="_blank" class="text-indigo-500 hover:underline">Privacy
                            Policy</a></label>
                    </div>

                    <div class="p-2 w-full mt-4 mb-10">
                        <button id="lp-erp-send" (click)="sendLpForm()"
                                class="click-tag flex mx-auto text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                            Send
                        </button>
                    </div>
                </div>
                <div class="flex flex-wrap -m-2" id="step2" [className]="fromGroupHidden ? 'block' : 'hidden'">
                    <div class="flex flex-col text-center mx-auto mt-4 mb-6">
                        <p class="text-center mx-auto">
                            Thank you for your email address! In order to accelerate the process, please fill out the info bellow.
                        </p>
                    </div>
                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="email" class="leading-7 text-sm text-gray-600">Name</label>
                            <input [formControlName]="'name'" type="text" id="name" name="name"
                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>
                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="company" class="leading-7 text-sm text-gray-600">Company/project</label>
                            <input [formControlName]="'company'" type="text" id="company" name="company"
                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>
                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="phone" class="leading-7 text-sm text-gray-600">Phone</label>
                            <input [formControlName]="'phone'" type="text" id="phone" name="phone"
                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>
                    <div class="p-2 w-full mb-4">
                        <div class="relative">
                            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                            <textarea [formControlName]="'message'" id="message" name="message"
                                      class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                    </div>
                    <h2 class="sm:text-xl p-2 text-lg font-medium title-font mb-4 text-gray-900">What can we help you with?</h2>
                    <div class="p-2 w-full">
                        <!--<div class="flex items-center mb-4">
                            <input [formControlName]="'erp'" id="erp" aria-describedby="erp" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="erp" class="ml-3 text-gray-900 dark:text-gray-300">ERP: Odoo set up and
                                customization</label>
                            &lt;!&ndash;                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>&ndash;&gt;
                        </div>-->
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'allocation'" id="allocation" aria-describedby="allocation" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="allocation" class="ml-3 text-gray-900 dark:text-gray-300">Development: software development for your company</label>
                            <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'funding'" id="funding" aria-describedby="funding" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="funding" class="ml-3 text-gray-900 dark:text-gray-300">Funding: application of state
                                subsidies for digitalization</label>
                            <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'consulting'" id="consulting" aria-describedby="consulting" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="consulting" class="ml-3 text-gray-900 dark:text-gray-300">Consulting: expert insights about your IT systems</label>
                            <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'srs'" id="srs" aria-describedby="srs" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="srs" class="ml-3 text-gray-900 dark:text-gray-300">Planning: writing of software requirements</label>
                            <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                        </div>
                        <!--<div class="flex mb-4">
                            <div class="flex items-center h-5">
                                <input id="shipping-2" aria-describedby="shipping-2" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="shipping-2" class="font-medium text-gray-900 dark:text-gray-300">Free shipping via Flowbite</label>
                                <div class="text-gray-500 dark:text-gray-300"><span class="text-xs font-normal">For orders shipped from Flowbite from <span class="font-medium">€ 25</span> in books or <span>€ 29</span> on other categories</span></div>
                            </div>
                        </div>

                        <div class="flex items-center">
                            <input id="international-shipping-disabled" aria-describedby="international-shipping-disabled" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" disabled>
                            <label for="international-shipping-disabled" class="ml-3 text-sm font-medium text-gray-300 dark:text-gray-500">Eligible for international shipping (disabled)</label>
                        </div>-->
                    </div>
                    <h2 class="sm:text-xl p-2 w-full text-lg font-medium title-font mb-4 text-gray-900">What is your
                        budget?</h2>

                    <div class="p-2 w-full">
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'budget'" id="budget-20" type="radio" name="budget" value="6"
                                   class="w-4 h-4 text-indigo-500 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:focus:bg-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                                   aria-labelledby="budget-20" aria-describedby="budget-20">
                            <label for="budget-20" class="block ml-2 text-gray-900 dark:text-gray-300">
                                Up to 20.000 €
                            </label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'budget'" id="budget-50" type="radio" name="budget" value="7"
                                   class="w-4 h-4 text-indigo-500 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:focus:bg-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                                   aria-labelledby="budget-50" aria-describedby="budget-50">
                            <label for="budget-50" class="block ml-2 text-gray-900 dark:text-gray-300">
                                20.000 € - 50.000 €
                            </label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'budget'" id="budget-100" type="radio" name="budget" value="8"
                                   class="w-4 h-4 text-indigo-500 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                                   aria-labelledby="budget-100" aria-describedby="budget-100">
                            <label for="budget-100" class="block ml-2 text-gray-900 dark:text-gray-300">
                                50.000 € - 100.000 €
                            </label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'budget'" id="budget-more" type="radio" name="budget" value="9"
                                   class="w-4 h-4 text-indigo-500 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                                   aria-labelledby="budget-more" aria-describedby="budget-more">
                            <label for="budget-more" class="block ml-2 text-gray-900 dark:text-gray-300">
                                More than 100.000 €
                            </label>
                        </div>
                    </div>
                    <div class="p-2 w-full mt-4 mb-10">
                        <button id="lp-erp-update" (click)="updateLpForm()"
                                class="click-tag flex mx-auto text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                            Send
                        </button>
                    </div>
                </div>
            </div>

            <!--                <h2 class="sm:text-xl p-2 w-full text-lg font-medium title-font mb-4 text-gray-900">Tell us more about
                                you</h2>-->


        </form>
    </div>
</section>
