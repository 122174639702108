<section class="text-gray-100 bg-indigo-500 md:bg-internet-white md:bg-center body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font text-gray-100 dark:text-gray-100 sm:text-4xl text-3xl mb-4 font-medium max-w-md">
                Trabalhe de casa
                ganhando em Euro
            </h1>
            <p class="mb-8 leading-relaxed text-gray-100 dark:text-gray-100 max-w-prose">
                Faça parte de uma equipe internacional de devs de alta performance e trabalhe com especialistas de ponta
                do mercado Europeu.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <button (click)="onClick('career')"
                        id="developers-br-lm"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                    Saiba mais
                </button>
                <a href="https://app.tekr.io" target="_blank"
                   id="developers-br-cta"
                   class="click-tag ml-2 md:ml-4 inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                    Criar conta
                </a>
            </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        </div>
    </div>
</section>

<section class="relative text-gray-600 bg-white dark:bg-gray-900 body-font" id="career">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-white dark:text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
            <!--            <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">FOR ANY KIND OF SOFTWARE</h2>-->
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4 max-w-prose mx-auto">
                Sem entrevistas e formulários,<br/>
                somente código
            </h1>
            <p class="mx-auto max-w-prose">Eleve sua carreira dentro de minutos. Pule todos os processos longos de entrevistas e vá direto ao ponto. Seu tempo é precioso.
            </p>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div
                class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>

                </svg>
            </div>
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Invista 30 minutos do seu tempo</h2>
                <p class="leading-relaxed text-base">Faça um ou mais desafios de programação. Se necessário, você
                    pode refazê-los quando quiser. Valorizamos muito sua evolução.
                </p>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Escolha os melhores projetos</h2>
                <p class="leading-relaxed text-base">Nossa IA te recomenda os melhores projetos conforme suas skills.
                    Trabalhe com qualquer stack relacionada aos seus desafios.
                </p>
            </div>
            <div
                class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
            <div
                class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Mande na sua própria agenda</h2>
                <p class="leading-relaxed text-base">Planeje os seus horários de acordo com os projetos e trabalhe a
                    partir de 10h, com rendimento de até 30K BRL / mês!
                </p>
            </div>
        </div>
        <button (click)="onClick('challenges')"
                id="developers-br-challenges"
                class="click-tag flex mx-auto mt-20 mb-10 text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
            Ir para os desafios
        </button>
    </div>
</section>


<section class="relative text-gray-100 body-font bg-gray-900 pb-10" id="challenges">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container max-w-4xl px-5 py-24 mx-auto">
        <div class="mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4">Desafios de programação</h1>
            <p class="leading-relaxed text-base max-w-prose">
                Há anos a parceria Europa - Brasil tem mostrado resultados incríveis em diversos setores. Chegou a
                hora de intensificar o da computação.
                <br/>
                <br/>
                Você pode <a class="click-tag text-indigo-500"
                             id="developers-br-challenges-cta"
                             href="https://app.tekr.io" target="_blank">criar uma conta gratuita</a> em nossa plataforma
                ou iniciar um desafio agora mesmo!
                Escolha uma ou mais áreas em que você é mais proficiente. Você concluirá um desafio de programação para que
                nossa IA possa te recomendar os melhores projetos.
            </p>
        </div>
        <h2 class="text-xl md:text-2xl title-font font-medium mt-12 mb-2">Inteligência artifical</h2>
        <div class="h-1 w-20 bg-indigo-500 rounded mb-12"></div>
        <div class="flex flex-wrap -mx-4 mb-10 -mt-4 md:space-y-0 space-y-6">
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:data-science-assessment-2f7uzeupcj"
               id="developers-br-challenges-ds"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"/>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">Ciência de dados</h3>
                </div>
            </a>
        </div>
        <h2 class="text-xl md:text-2xl title-font font-medium mt-12 mb-2">Linguages de
            programação</h2>
        <div class="h-1 w-20 bg-indigo-500 rounded mb-12"></div>
        <div class="flex flex-wrap -mx-4 mb-10 -mt-4 md:space-y-0 space-y-6">
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:javascript-assessment-nztli2hbgw"
               id="developers-br-challenges-js"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">JavaScript</h3>
                    <!--<a class="mt-3 text-indigo-500 inline-flex items-center">Begin
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </a>-->
                </div>
            </a>
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:python-assessment-15b8cc6xig"
               id="developers-br-challenges-py"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">Python</h3>
                </div>
            </a>
        </div>
        <h2 class="text-xl md:text-2xl title-font font-medium mt-12 mb-2">Frameworks de frontend</h2>
        <div class="h-1 w-20 bg-indigo-500 rounded mb-12"></div>
        <div class="flex flex-wrap -mx-4 mb-10 -mt-4 md:space-y-0 space-y-6">
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:angular-assessment-p02dpyafo9"
               id="developers-br-challenges-ng"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">Angular</h3>
                </div>
            </a>
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:react-assessment-bts5x4v8uy"
               id="developers-br-challenges-react"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">React</h3>
                </div>
            </a>
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:vuejs-assessment-mblfdr95aq"
               id="developers-br-challenges-vue"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">Vue.js</h3>
                </div>
            </a>
        </div>
        <h2 class="text-xl md:text-2xl title-font font-medium mt-12 mb-2">Habilidades
            de backend</h2>
        <div class="h-1 w-20 bg-indigo-500 rounded mb-12"></div>
        <div class="flex flex-wrap -mx-4 -mt-4 md:space-y-0 space-y-6">
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:nodejs-assessment-h7uwkp4112"
               id="developers-br-challenges-node"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">Node.js</h3>
                </div>
            </a>
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:mongodb-assessment-ovsu5jhvhs"
               id="developers-br-challenges-mongo"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">MongoDB</h3>
                </div>
            </a>
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:sql-assessment-53eaf6w0uu"
               id="developers-br-challenges-sql"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">SQL</h3>
                </div>
            </a>
            <a href="https://tekr.coderbyte.com/sl-candidate?promo=tekriotecho-xfswd:graphql-assessment-r45gs5wjtw"
               id="developers-br-challenges-gql"
               target="_blank" class="click-tag p-4 w-full md:w-1/3 flex flex-col text-center items-center">
                <div
                    class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 mb-5 flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"/>
                    </svg>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg title-font font-medium mb-3">GraphQL</h3>
                </div>
            </a>
        </div>
    </div>
</section>

<section class="relative text-gray-600 bg-white dark:bg-gray-900 body-font pb-10">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-white dark:text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto" bis_skin_checked="1">
        <div class="text-center mb-20" bis_skin_checked="1">
            <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Seu estilo de vida na
                tekr</h1>
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto max-w-prose">
                Não importa se você é early bird ou night owl. Você decide sua agenda e carga de trabalho.
                Somos family friendly e apoiamos seus planos de vida :)
            </p>
        </div>
        <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2" bis_skin_checked="1">
            <div class="p-2 sm:w-1/2 w-full" bis_skin_checked="1">
                <div class="bg-gray-100 rounded flex p-4 h-full items-center" bis_skin_checked="1">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span class="title-font font-medium">Trabalhe a qualquer hora 100% remotamente</span>
                </div>
            </div>
            <div class="p-2 sm:w-1/2 w-full" bis_skin_checked="1">
                <div class="bg-gray-100 rounded flex p-4 h-full items-center" bis_skin_checked="1">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span class="title-font font-medium">Ganhe até 30K BRL / mês em rendimentos</span>
                </div>
            </div>
            <div class="p-2 sm:w-1/2 w-full" bis_skin_checked="1">
                <div class="bg-gray-100 rounded flex p-4 h-full items-center" bis_skin_checked="1">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span class="title-font font-medium">Receba em qualquer moeda [EUR, BRL, BTC, ...]</span>
                </div>
            </div>
            <div class="p-2 sm:w-1/2 w-full" bis_skin_checked="1">
                <div class="bg-gray-100 rounded flex p-4 h-full items-center" bis_skin_checked="1">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span class="title-font font-medium">Trabalhe a partir de 10 horas semanais</span>
                </div>
            </div>
            <div class="p-2 sm:w-1/2 w-full" bis_skin_checked="1">
                <div class="bg-gray-100 rounded flex p-4 h-full items-center" bis_skin_checked="1">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span class="title-font font-medium">Aproveite férias flexíveis e mais longas</span>
                </div>
            </div>
            <div class="p-2 sm:w-1/2 w-full" bis_skin_checked="1">
                <div class="bg-gray-100 rounded flex p-4 h-full items-center" bis_skin_checked="1">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                         stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                    <span class="title-font font-medium">Tenha acesso à formação gratuita e networking</span>
                </div>
            </div>
        </div>

        <button
            (click)="goToLink('https://app.tekr.io')"
            id="developers-br-lifestyle-cta"
            class="click-tag flex flex-auto mx-auto text-center mt-20 mb-10 text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
            Criar conta gratuita
        </button>
    </div>
</section>


<section class="relative text-gray-600 bg-gray-100 md:bg-connections-white-inv md:bg-top body-font" id="reminder">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Na correria agora?</h1>
        </div>

        <div class="lg:w-1/2 md:w-2/3 mx-auto">
            <form [formGroup]="devForm" id="devForm">
                <div class="flex flex-wrap -m-2" id="step1" [className]="fromGroupHidden ? 'hidden' : 'block'">
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-center max-w-prose mb-10">Deixe seu
                        e-mail que nós iremos te lembrar sobre essa oportunidade.</p>
                    <div class="p-2 max-w-prose mx-auto text-center">
                        <div class="relative">
                            <input [formControlName]="'email'" type="email" id="email" name="email" placeholder="Email"
                                   class="bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>
                    <div class="flex items-center align-middle justify-center p-2 mb-4">
                        <input [formControlName]="'agreement'" id="agreement" aria-describedby="agreement"
                               type="checkbox"
                               class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="agreement" class="ml-3 text-gray-900 dark:text-gray-300">Eu aceito a <a
                            href="https://tekr.io/privacy-policy" target="_blank" class="text-indigo-500 hover:underline">Política de Privacidade</a></label>
                    </div>
                    <div class="p-2 w-full mt-4 mb-10">
                        <button (click)="sendDevForm()"
                                id="developers-br-remind"
                                class="click-tag flex mx-auto text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
                            Lembre-me
                        </button>
                    </div>
                </div>
                <div class="flex flex-wrap -m-2" id="step2" [className]="fromGroupHidden ? 'block' : 'hidden'">
                    <div class="flex flex-col text-center mx-auto mt-4 mb-6">
                        <p class="text-center mx-auto">
                            Você receberá um lembrete em alguns dias!<br/><br/>
                            Se ainda tiver um minutinho, responda às perguntas abaixo :)
                        </p>
                    </div>

                    <h2 class="p-2 text-lg font-medium title-font mb-4 text-gray-900 mx-auto">Quais são suas melhores
                        habilidades de programação?</h2>
                    <div class="flex flex-wrap max-w-prose">
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'dataScience'" id="dataScience" aria-describedby="dataScience"
                                   type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="dataScience" class="ml-3 text-gray-900 dark:text-gray-300">AI</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'blockchain'" id="blockchain" aria-describedby="blockchain"
                                   type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="blockchain" class="ml-3 text-gray-900 dark:text-gray-300">Blockchain</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'js'" id="js" aria-describedby="js" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="js" class="ml-3 text-gray-900 dark:text-gray-300">JavaScript</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'python'" id="python" aria-describedby="python" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="python" class="ml-3 text-gray-900 dark:text-gray-300">Python</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'erp'" id="erp" aria-describedby="erp"
                                   type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="erp" class="ml-3 text-gray-900 dark:text-gray-300">Odoo</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'angular'" id="angular" aria-describedby="angular"
                                   type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="angular" class="ml-3 text-gray-900 dark:text-gray-300">Angular</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'react'" id="react" aria-describedby="react" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="react" class="ml-3 text-gray-900 dark:text-gray-300">React</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'vue'" id="vue" aria-describedby="vue" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="vue" class="ml-3 text-gray-900 dark:text-gray-300">Vue.js</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'node'" id="node" aria-describedby="node" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="node" class="ml-3 text-gray-900 dark:text-gray-300">Node.js</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'mongo'" id="mongo" aria-describedby="mongo" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="mongo" class="ml-3 text-gray-900 dark:text-gray-300">MongoDB</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'sql'" id="sql" aria-describedby="sql" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="sql" class="ml-3 text-gray-900 dark:text-gray-300">SQL</label>
                        </div>
                        <div class="flex items-center mb-4 w-1/3 mx-auto p-2">
                            <input [formControlName]="'graphql'" id="graphql" aria-describedby="graphql" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400
                            dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                            dark:border-gray-600">
                            <label for="graphql" class="ml-3 text-gray-900 dark:text-gray-300">GraphQL</label>
                        </div>
                    </div>

                    <div class="p-2 w-full mx-auto">
                        <div class="relative">
                            <label for="message" class="leading-7 text-sm text-gray-600">Quais são suas outras dev skills?</label>
                            <textarea [formControlName]="'message'" id="message" name="message"
                                      class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300
                            focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 h-24 text-base
                            outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200
                            ease-in-out"></textarea>
                        </div>
                    </div>

                    <div class="p-2 w-full mx-auto">
                        <div class="relative">
                            <label for="name" class="leading-7 text-sm text-gray-600">Qual é o seu usuário do
                                github?</label>
                            <input [formControlName]="'name'" type="text" id="name" name="name"
                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300
                            focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base
                            outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>

                    <div class="p-2 w-full mt-4 mb-10">
                        <button (click)="updateDevForm()"
                                id="developers-remind-update"
                                class="click-tag flex mx-auto text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
                            Enviar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
