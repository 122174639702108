import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
    trustedUrl: any;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        if (localStorage.getItem('refreshed') !== 'true') {
            localStorage.setItem('refreshed', 'true')
            location.reload()
        }
    }

}
