import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Meta} from '@angular/platform-browser';

@Component({
    selector: 'tekr-saas',
    templateUrl: './saas.component.html',
    styleUrls: ['./saas.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaasComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, private metaTagService: Meta,
                private ref: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.metaTagService.updateTag(
            {
                name: 'keywords',
                content: 'OOOii',
            },
            'keywords'
        );
        localStorage.setItem('refreshed', 'false')
        this.ref.markForCheck()
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }
}
