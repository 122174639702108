import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
// import {OdooConnector} from '../../../ng-odoo-connect/odoo-connector.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-careers-br',
    templateUrl: './careers-br.component.html',
    styleUrls: ['./careers-br.component.css']
})
export class CareersBrComponent implements OnInit {

    /*odoo = new OdooConnector(
        'https://backoffice.tekr.io',
        // 'https://stage.backoffice.tekr.io',
        // 'http://backoffice.local',
        'tekr-erp',
        'admin@tekr.io',
        'e2762fa8e08d3b58ee9622d93cef8f958a295420',
        // '95b9e3f1c197915e7763ab8e448d49ffcdc714c8',
        // '2897720084af565e7f765b81f89fede9d4dad20d'
    );*/

    devForm: FormGroup;
    fromGroupHidden = false;

    constructor(private viewportScroller: ViewportScroller, private ref: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.devForm = new FormGroup({
            email: new FormControl(),
            name: new FormControl(),
            message: new FormControl(),
            dataScience: new FormControl(),
            blockchain: new FormControl(),
            js: new FormControl(),
            python: new FormControl(),
            erp: new FormControl(),
            angular: new FormControl(),
            react: new FormControl(),
            vue: new FormControl(),
            node: new FormControl(),
            mongo: new FormControl(),
            sql: new FormControl(),
            graphql: new FormControl(),
            agreement: new FormControl(),
        });
        localStorage.setItem('refreshed', 'false')
    }

    sendDevForm(): void {
        const formData = this.devForm.getRawValue();
        if (!formData.email) return alert('Por favor, digite o seu endereço de e-mail.');
        if (!formData.agreement) return alert('Please agree to our Privacy Policy.');

        const tags = [];
        tags.push(11);

        const devData = {
            email: formData.email,
            name: formData.email,
            category_id: tags,
            // team_id: 1
        };

        this.createDev(devData);
    }

    public updateDevForm(): void {
        const id = localStorage.getItem('devId');
        const formData = this.devForm.getRawValue();

        const tags = [];
        tags.push(11);

        if (formData.dataScience) {
            tags.push(12);
        }
        if (formData.blockchain) {
            tags.push(5);
        }
        if (formData.js) {
            tags.push(13);
        }
        if (formData.python) {
            tags.push(14);
        }
        if (formData.erp) {
            tags.push(2);
        }
        if (formData.angular) {
            tags.push(15);
        }
        if (formData.react) {
            tags.push(16);
        }
        if (formData.vue) {
            tags.push(17);
        }
        if (formData.node) {
            tags.push(18);
        }
        if (formData.mongo) {
            tags.push(19);
        }
        if (formData.sql) {
            tags.push(20);
        }
        if (formData.graphql) {
            tags.push(21);
        }

        const devData = {
            email: localStorage.getItem('devMail'),
            name: formData.name || localStorage.getItem('devMail'),
            comment: formData.message,
            category_id: tags,
            // team_id: 1
        };

        this.updateDev(id, devData);
    }

    // tslint:disable-next-line:typedef
    createDev(values) {
        // [Demo] Get Odoo server Data
        // this.odoo.data().subscribe((res: any) => res);

        localStorage.setItem('devMail', values.email);
        this.fromGroupHidden = true;
        this.ref.detectChanges();
        this.onClick('dev');
// [Demo] LogIn & SearchRead res.partner
        /*this.odoo.login().subscribe((res: any) => {
            this.odoo.create(
                'res.partner',
                values
                // [['customer', '=', true]],
                // {fields: ['name'], limit: 5}
            ).subscribe((id: any) => {
                localStorage.setItem('devId', id);
                localStorage.setItem('devMail', values.email);
                this.fromGroupHidden = true;
                this.ref.detectChanges();
                this.onClick('dev');
            });
        });*/
    }

    // tslint:disable-next-line:typedef
    updateDev(id, values) {
        // [Demo] Get Odoo server Data
        // this.odoo.data().subscribe((res: any) => res);

        document.getElementById('devForm').innerHTML = '' +
            '<div class="flex flex-col text-center mx-auto mb-10">' +
            '<p>' +
            'Muito obrigado pelas informações!' +
            '</p>' +
            '</div>';
// [Demo] LogIn & SearchRead res.partner
        /*this.odoo.login().subscribe((res: any) => {
            this.odoo.create(
                'res.partner',
                values
                // [['customer', '=', true]],
                // {fields: ['name'], limit: 5}
            ).subscribe((obj: any) => {
                // console.log(obj);
                document.getElementById('devForm').innerHTML = '' +
                    '<div class="flex flex-col text-center mx-auto mb-10">' +
                    '<p>' +
                    'Muito obrigado pelas informações!' +
                    '</p>' +
                    '</div>';
                this.onClick('dev');
                this.odoo.delete(
                    'res.partner',
                    parseInt(id)
                    // [['customer', '=', true]],
                    // {fields: ['name'], limit: 5}
                ).subscribe((obj: any) => {
                    // console.log(obj);
                })
            });
        });*/
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }
}
