<mat-dialog-content>
    <div id="ytPlayer">
        <iframe width="100%" height="380px" [src]="data.url | secure | async"
                title="Odoo ERP" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                controls="0"
                modestbranding
                allowfullscreen></iframe>
    </div>
</mat-dialog-content>
<!--<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>-->
