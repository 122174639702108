// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'http://localhost:1337/api/',
    authToken: '09557a7fb53951a5e880d96c2b872729b533a253236a5ff0815f01a6ebf55250180f556035ae16ae0ce014785467e1edf1be1b45bb3cfeed9d1f89a70c01a499a793f4f8b946b347d9845ee5f0472752da6511bc3bab561cc0fba149cc4e9abf2ef2ae90ccdd13919a6bccd1647c06517c22c8a5d5e7fd3c9c7ff0ee11bc9cf9'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
