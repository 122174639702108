import {Component, OnInit} from '@angular/core';
import {ContentService} from '../content.service';

@Component({
    selector: 'tekr-contact-section',
    templateUrl: './contact-section.component.html',
    styleUrls: ['./contact-section.component.css']
})
export class ContactSectionComponent implements OnInit {

    content$ = this.contentService.getContent('ctas/1')

    constructor(private contentService: ContentService) {
    }

    ngOnInit(): void {
    }

}
