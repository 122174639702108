<section class="text-gray-600 bg-white dark:bg-gray-900 md:bg-connections-purple md:bg-top body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-lg">
                State-funded web, mobile and AI development
            </h1>
            <p class="mb-8 leading-relaxed max-w-prose">
                IT services that increase software productivity.
                From web to ML applications, we help fund and develop software under highest quality
                standards.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <button (click)="onClick('lp')"
                        id="lp-ai-lm"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                    Learn more
                </button>
                <button (click)="onClick('contact-lp')"
                        id="lp-ai-cta"
                        class="click-tag ml-2 md:ml-4 inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 rounded text-lg">
                    Contact us
                </button>
            </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        </div>
    </div>
</section>

<section class="text-gray-600 bg-indigo-500 body-font md:bg-people-white-inv md:bg-top" id="lp">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-center text-2xl font-medium title-font mb-2 text-gray-100 dark:text-gray-100">
                        We develop fullstack<br/>
                        for endless possibilities
                    </h1>
                    <!--                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>-->
                    <!--<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>-->
                </div>
            </div>
        </div>
    </div>
    <div>

        <div class="container px-5 py-5 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="click-tag text-gray-900 text-lg title-font font-medium mb-3 cursor-pointer hover:underline"
                                routerLink="/saas" id="lp-ai-saas">
                                SaaS development
                            </h2>
                            <p class="leading-relaxed text-base">
                                Fullstack platform development. Mobile apps, custom APIs, bots, automation, payment and
                                subscriptions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                <!--<path stroke-linecap="round" stroke-linejoin="round"
                                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>-->
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="click-tag text-gray-900 text-lg title-font font-medium mb-3 cursor-pointer hover:underline"
                                routerLink="/contact" id="lp-ai-funding">
                                State funding
                            </h2>
                            <p class="leading-relaxed text-base">
                                Get up to 50% of your project's budget reimbursed by the EU within days after
                                documentation approval.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div>
        <div class="container px-5 py-5 pb-28 md:pb-36 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="click-tag text-gray-900 text-lg title-font font-medium mb-3 cursor-pointer hover:underline"
                                routerLink="/ai" id="lp-ai-ai">
                                AI development
                            </h2>
                            <p class="leading-relaxed text-base">
                                Productivity tools that mimic human ability. Text and image generation, question
                                answering, assistants.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">

                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="click-tag text-gray-900 text-lg title-font font-medium mb-3 cursor-pointer hover:underline"
                                routerLink="/contact" id="lp-ai-consulting">
                                Expert consulting
                            </h2>
                            <p class="leading-relaxed text-base">
                                Allow us to review your product and software requirements and lead you towards the best
                                solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap mx-auto items-center mt-20">
                <button id="lp-ai-contact-us" (click)="onClick('contact-lp')"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-200 dark:hover:bg-indigo-200 rounded text-lg">
                    Contact us
                </button>
            </div>
        </div>
    </div>
</section>

<tekr-pipeline-section></tekr-pipeline-section>

<tekr-contact-section></tekr-contact-section>

<section class="relative text-gray-600 bg-gray-100 md:bg-connections-white-inv md:bg-top body-font" id="contact-lp">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Let's talk</h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base max-w-prose">
                Our team will contact you within one business day.
            </p>
        </div>

        <form [formGroup]="lpForm" id="lpForm">
            <div class="lg:w-1/2 md:w-2/3 mx-auto max-w-md">
                <div class="flex flex-wrap -m-2" id="step1" [className]="fromGroupHidden ? 'hidden' : 'block'">
                    <div class="p-2 max-w-prose mx-auto text-center">
                        <div class="relative">
                            <input [formControlName]="'email'" type="email" id="email" name="email" placeholder="Email"
                                   class="bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>

                    <div class="flex items-center align-middle justify-center p-2 mb-4">
                        <input [formControlName]="'agreement'" id="agreement" aria-describedby="agreement"
                               type="checkbox"
                               class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="agreement" class="ml-3 text-gray-900 dark:text-gray-300">
                            I agree to the <a href="https://tekr.io/privacy-policy" target="_blank"
                                              class="text-indigo-500 hover:underline">
                            Privacy Policy</a>
                        </label>
                    </div>

                    <div class="p-2 w-full mt-4 mb-10">
                        <button id="lp-ai-send" (click)="sendLpForm()"
                                class="click-tag flex mx-auto text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                            Send
                        </button>
                    </div>
                </div>
                <div class="flex flex-wrap -m-2" id="step2" [className]="fromGroupHidden ? 'block' : 'hidden'">
                    <div class="flex flex-col text-center mx-auto mt-4 mb-6">
                        <p class="text-center mx-auto">
                            Thank you for your email address! In order to accelerate the process, please fill out the
                            info bellow.
                        </p>
                    </div>
                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="email" class="leading-7 text-sm text-gray-600">Name</label>
                            <input [formControlName]="'name'" type="text" id="name" name="name"
                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>
                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="company" class="leading-7 text-sm text-gray-600">Company/project</label>
                            <input [formControlName]="'company'" type="text" id="company" name="company"
                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>
                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="phone" class="leading-7 text-sm text-gray-600">Phone</label>
                            <input [formControlName]="'phone'" type="text" id="phone" name="phone"
                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>
                    <div class="p-2 w-full mb-4">
                        <div class="relative">
                            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                            <textarea [formControlName]="'message'" id="message" name="message"
                                      class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white dark:bg-gray-900 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                    </div>
                    <h2 class="sm:text-xl p-2 text-lg font-medium title-font mb-4 text-gray-900">What can we help you
                        with?</h2>
                    <div class="p-2 w-full">
                        <!--<div class="flex items-center mb-4">
                            <input [formControlName]="'erp'" id="erp" aria-describedby="erp" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="erp" class="ml-3 text-gray-900 dark:text-gray-300">ERP: Odoo set up and
                                customization</label>
                            &lt;!&ndash;                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>&ndash;&gt;
                        </div>-->
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'allocation'" id="allocation" aria-describedby="allocation"
                                   type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="allocation" class="ml-3 text-gray-900 dark:text-gray-300">Development: software
                                development for your project</label>
                            <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'funding'" id="funding" aria-describedby="funding" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="funding" class="ml-3 text-gray-900 dark:text-gray-300">Funding: application of
                                state
                                subsidies for digitalization</label>
                            <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'consulting'" id="consulting" aria-describedby="consulting"
                                   type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="consulting" class="ml-3 text-gray-900 dark:text-gray-300">Consulting: expert
                                insights about your project</label>
                            <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'srs'" id="srs" aria-describedby="srs" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="srs" class="ml-3 text-gray-900 dark:text-gray-300">Planning: writing of software
                                requirements</label>
                            <!--                        <label for="erp" class="ml-3 text-sm text-gray-900 dark:text-gray-300">I agree to the <a href="#" class="text-indigo-500 hover:underline dark:text-indigo-400">terms and conditions</a></label>-->
                        </div>
                        <!--<div class="flex mb-4">
                            <div class="flex items-center h-5">
                                <input id="shipping-2" aria-describedby="shipping-2" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="shipping-2" class="font-medium text-gray-900 dark:text-gray-300">Free shipping via Flowbite</label>
                                <div class="text-gray-500 dark:text-gray-300"><span class="text-xs font-normal">For orders shipped from Flowbite from <span class="font-medium">€ 25</span> in books or <span>€ 29</span> on other categories</span></div>
                            </div>
                        </div>

                        <div class="flex items-center">
                            <input id="international-shipping-disabled" aria-describedby="international-shipping-disabled" type="checkbox" class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" disabled>
                            <label for="international-shipping-disabled" class="ml-3 text-sm font-medium text-gray-300 dark:text-gray-500">Eligible for international shipping (disabled)</label>
                        </div>-->
                    </div>
                    <h2 class="sm:text-xl p-2 text-lg font-medium title-font mb-4 text-gray-900">Which solutions do you
                        need?</h2>
                    <div class="p-2 w-full">
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'saas'" id="saas" aria-describedby="saas" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="saas" class="ml-3 text-gray-900 dark:text-gray-300">SaaS: mobile apps, APIs,
                                automation,
                                payment solutions</label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'ai'" id="ai" aria-describedby="ai" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="ai" class="ml-3 text-gray-900 dark:text-gray-300">AI: smart product enhancement
                                or new
                                service</label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'blockchain'" id="blockchain" aria-describedby="blockchain"
                                   type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="blockchain" class="ml-3 text-gray-900 dark:text-gray-300">Blockchain: DAOs,
                                DeFi, smart
                                contracts, NFTs</label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'others'" id="others" aria-describedby="others" type="checkbox"
                                   class="w-4 h-4 text-indigo-500 bg-gray-100 rounded border-gray-300 focus:ring-indigo-400 dark:focus:ring-indigo-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="others" class="ml-3 text-gray-900 dark:text-gray-300">Others</label>
                        </div>

                    </div>

                    <h2 class="sm:text-xl p-2 w-full text-lg font-medium title-font mb-4 text-gray-900">What is your
                        budget?</h2>

                    <div class="p-2 w-full">
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'budget'" id="budget-20" type="radio" name="budget" value="6"
                                   class="w-4 h-4 text-indigo-500 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:focus:bg-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                                   aria-labelledby="budget-20" aria-describedby="budget-20">
                            <label for="budget-20" class="block ml-2 text-gray-900 dark:text-gray-300">
                                Up to 20.000 €
                            </label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'budget'" id="budget-50" type="radio" name="budget" value="7"
                                   class="w-4 h-4 text-indigo-500 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:focus:bg-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                                   aria-labelledby="budget-50" aria-describedby="budget-50">
                            <label for="budget-50" class="block ml-2 text-gray-900 dark:text-gray-300">
                                20.000 € - 50.000 €
                            </label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'budget'" id="budget-100" type="radio" name="budget" value="8"
                                   class="w-4 h-4 text-indigo-500 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                                   aria-labelledby="budget-100" aria-describedby="budget-100">
                            <label for="budget-100" class="block ml-2 text-gray-900 dark:text-gray-300">
                                50.000 € - 100.000 €
                            </label>
                        </div>
                        <div class="flex items-center mb-4">
                            <input [formControlName]="'budget'" id="budget-more" type="radio" name="budget" value="9"
                                   class="w-4 h-4 text-indigo-500 border-gray-300 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:bg-gray-700 dark:border-gray-600"
                                   aria-labelledby="budget-more" aria-describedby="budget-more">
                            <label for="budget-more" class="block ml-2 text-gray-900 dark:text-gray-300">
                                More than 100.000 €
                            </label>
                        </div>
                    </div>
                    <div class="p-2 w-full mt-4 mb-10">
                        <button id="lp-ai-update" (click)="updateLpForm()"
                                class="click-tag flex mx-auto text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                            Send
                        </button>
                    </div>
                </div>
            </div>

            <!--                <h2 class="sm:text-xl p-2 w-full text-lg font-medium title-font mb-4 text-gray-900">Tell us more about
                                you</h2>-->


        </form>
    </div>
</section>
