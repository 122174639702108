import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + environment.authToken
    };

    constructor(private http: HttpClient) {
    }

    getContent(path) {
        return this.http.get<any>(environment.apiUrl + path, {headers: this.headers});
    }
}
